import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step`}</strong>{` -- plumbing for distributed systems`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step <command> [arguments]
`}</code></pre>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--help`}</strong>{`, `}<strong parentName="p">{`-h`}</strong>{`
show help`}</p>
    <p><strong parentName="p">{`--config`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
path to the config file to use for CLI flags`}</p>
    <p><strong parentName="p">{`--version`}</strong>{`, `}<strong parentName="p">{`-v`}</strong>{`
print the version`}</p>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "help/"
              }}>{`help, h`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`display help for the specified command or command group`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "version/"
              }}>{`version`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`display the current version of the cli`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "base64/"
              }}>{`base64`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`encodes and decodes using base64 representation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "ca/"
              }}>{`ca`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`initialize and manage a certificate authority`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "beta/"
              }}>{`beta`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`commands that are being tested; these APIs are likely to change`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "certificate/"
              }}>{`certificate`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`create, revoke, validate, bundle, and otherwise manage certificates`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "completion/"
              }}>{`completion`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`print the shell completion script`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "context/"
              }}>{`context`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`manage certificate authority contexts`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "crl/"
              }}>{`crl`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`initialize and manage a certificate revocation list`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "crypto/"
              }}>{`crypto`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`useful cryptographic plumbing`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "oauth/"
              }}>{`oauth`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`authorization and single sign-on using OAuth & OIDC`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "path/"
              }}>{`path`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`print the configured step path and exit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "ssh/"
              }}>{`ssh`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`create and manage ssh certificates`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Version`}</h2>
    <p>{`Smallstep CLI/0.23.4 (linux/amd64)`}</p>
    <h2>{`Copyright`}</h2>
    <p>{`(c) 2018-2023 Smallstep Labs, Inc.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      